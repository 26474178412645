import React, { useState, useEffect, useContext } from "react";
import {
  Table,
  Thead,
  Tbody,
  Tr,
  Th,
  Td,
  Box,
  TableContainer,
  Text,
  Flex,
  Input,
  Heading,
  InputGroup,
  InputLeftElement,
  Spinner,
  Button,
} from "@chakra-ui/react";
import { SearchIcon } from "@chakra-ui/icons";
import SkillsModal from "../../SkillsModal";
import { AuthContext } from "../../../context";

const SkillsTable = () => {
  const [filter, setFilter] = useState("");
  const [tasks, setTasks] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState("");
  const [listOfSkills, setListOfSkills] = useState([]);
  const [skillsDisplayed, setSkillsDisplayed] = useState([]);
  const [currentPage, setCurrentPage] = useState(1); // Pagination state
  const itemsPerPage = 5; // Maximum items per page

  const {
    getUserAssessments,
    generatePrivateAssessmentUrl,
    getListOfSkills,
    addAssessments,
  } = useContext(AuthContext);

  const handleGenerateUrl = async (assessmentId) => {
    try {
      const url = await generatePrivateAssessmentUrl(assessmentId);
      window.open(url, "_blank"); // Open the URL in a new window
    } catch (error) {
      console.error("Error generating assessment URL:", error);
    }
  };

  useEffect(() => {
    const fetchSkills = async () => {
      try {
        const skillsList = await getListOfSkills();
        setListOfSkills(skillsList);
      } catch (error) {
        // Handle error
      }
    };

    fetchSkills();
  }, []);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const data = await getUserAssessments();
        setTasks(data);
        setLoading(false);
      } catch (err) {
        setError(
          err.message ||
            "Failed to retrieve user assessments. Please try again."
        );
        setLoading(false);
      }
    };

    fetchData();
  }, []);

  useEffect(() => {
    const listOfTestsIds = tasks.map((task) => task.testId);
    const filteredSkills = listOfSkills.filter(
      (skill) => !listOfTestsIds.includes(skill.id)
    );
    setSkillsDisplayed(filteredSkills);
  }, [listOfSkills, tasks]);

  const filteredTasks = tasks.filter(
    (task) =>
      task.name.toLowerCase().includes(filter.toLowerCase()) ||
      task.seniority?.toLowerCase().includes(filter.toLowerCase())
  );

  // Pagination logic
  const totalPages = Math.ceil(filteredTasks.length / itemsPerPage);
  const indexOfLastTask = currentPage * itemsPerPage;
  const indexOfFirstTask = indexOfLastTask - itemsPerPage;
  const currentTasks = filteredTasks.slice(indexOfFirstTask, indexOfLastTask);

  const handlePreviousPage = () => {
    setCurrentPage((prevPage) => prevPage - 1);
  };

  const handleNextPage = () => {
    setCurrentPage((prevPage) => prevPage + 1);
  };

  useEffect(() => {
    // Reset to page 1 when filter changes
    setCurrentPage(1);
  }, [filter]);

  if (loading) {
    return (
      <Flex justifyContent="center" alignItems="center" height="200px">
        <Spinner size="xl" />
      </Flex>
    );
  }

  if (error) {
    return (
      <Box backgroundColor="white" p={6} borderRadius="10px">
        <Text color="red.500" textAlign="center">
          {error}
        </Text>
      </Box>
    );
  }

  return (
    <Box backgroundColor="white" p={6} borderRadius="10px">
      <Flex justifyContent="space-between" alignItems="center" mb={4}>
        <Heading fontSize={28}>Skills</Heading>
        <Flex alignItems="center">
          <InputGroup mr={4}>
            <InputLeftElement pointerEvents="none" mt="2px">
              <SearchIcon color="gray.300" />
            </InputLeftElement>
            <Input
              placeholder="Search"
              borderRadius="8px"
              border="1px solid #E5E6E6"
              background="white"
              h={10}
              value={filter}
              onChange={(e) => setFilter(e.target.value)}
            />
          </InputGroup>
          <SkillsModal
            skillsDisplayed={skillsDisplayed}
            addAssessments={addAssessments}
          />
        </Flex>
      </Flex>
      <TableContainer backgroundColor="white">
        <Table>
          <Thead backgroundColor="#DCEBFA">
            <Tr>
              <Th fontWeight={400} color="#000F2B" fontSize={14}>
                Seniority
              </Th>
              <Th fontWeight={400} color="#000F2B" fontSize={14}>
                Skill
              </Th>
              <Th fontWeight={400} color="#000F2B" fontSize={14}>
                Score
              </Th>
              <Th fontWeight={400} color="#000F2B" fontSize={14}>
                Passed
              </Th>
              <Th
                fontWeight={400}
                color="#000F2B"
                fontSize={14}
                textAlign="right"
              >
                Retake
              </Th>
            </Tr>
          </Thead>
          <Tbody>
            {currentTasks.length > 0 ? (
              currentTasks.map((task, index) => (
                <Tr key={index}>
                  <Td fontWeight={500} color="#000F2B" fontSize={14}>
                    {task.seniority ? task.seniority : "N/A"}
                  </Td>
                  <Td fontWeight={400} color="#000F2B" fontSize={14}>
                    {task.name}
                  </Td>
                  <Td fontWeight={400} color="#000F2B" fontSize={14}>
                    {task.status === "pending" ? "Pending" : task.score}
                  </Td>
                  <Td fontWeight={400} color="#000F2B" fontSize={14}>
                    <Box
                      width="60px"
                      height="30px"
                      display="flex"
                      alignItems="center"
                      justifyContent="center"
                      color={task.score >= 50 ? "#00B833" : "#FF0000"}
                      bg={task.score >= 50 ? "#E8FFDD" : "#FDD"}
                      borderRadius="md"
                      boxShadow="sm"
                    >
                      <Text fontSize={16} fontWeight="bold">
                        {task.score >= 50 ? "Yes" : "No"}
                      </Text>
                    </Box>
                  </Td>
                  <Td
                    fontWeight={400}
                    color="#000F2B"
                    fontSize={14}
                    width="40%"
                    textAlign="right"
                  >
                    {task.available ? (
                      <Button
                        sx={{
                          borderRadius: "8px",
                          background: "#016DDA",
                          color: "white",
                          textAlign: "center",
                          fontFamily: "Segoe UI",
                          fontSize: "14px",
                          fontWeight: 600,
                          _hover: { background: "#004AA4" },
                        }}
                        py={5}
                        onClick={() => handleGenerateUrl(task.testId)}
                      >
                        {task.attemps ? "Take" : "Take Again"}
                      </Button>
                    ) : (
                      "Not Available"
                    )}
                  </Td>
                </Tr>
              ))
            ) : (
              <Tr>
                <Td colSpan="6" textAlign="center">
                  No skills found.
                </Td>
              </Tr>
            )}
          </Tbody>
        </Table>
      </TableContainer>
      {/* Pagination Controls */}
      <Flex justifyContent="space-between" alignItems="center" mt={4}>
        <Button
          onClick={handlePreviousPage}
          disabled={currentPage === 1}
          sx={{
            borderRadius: "8px",
            background: currentPage === 1 ? "#E2E8F0" : "#016DDA",
            color: currentPage === 1 ? "gray.500" : "white",
            textAlign: "center",
            fontFamily: "Segoe UI",
            fontSize: "14px",
            fontWeight: 600,
            cursor: currentPage === 1 ? "not-allowed" : "pointer",
            _hover: { background: "#004AA4" },
          }}
        >
          Previous
        </Button>
        <Text>
          Page {currentPage} of {totalPages}
        </Text>
        <Button
          onClick={handleNextPage}
          disabled={currentPage === totalPages || totalPages === 0}
          sx={{
            borderRadius: "8px",
            background:
              currentPage === totalPages || totalPages === 0
                ? "#E2E8F0"
                : "#016DDA",
            color:
              currentPage === totalPages || totalPages === 0
                ? "gray.500"
                : "white",
            textAlign: "center",
            fontFamily: "Segoe UI",
            fontSize: "14px",
            fontWeight: 600,
            cursor:
              currentPage === totalPages || totalPages === 0
                ? "not-allowed"
                : "pointer",
            _hover: { background: "#004AA4" },
          }}
        >
          Next
        </Button>
      </Flex>
    </Box>
  );
};

export default SkillsTable;