import React, { useContext, useState } from "react";
import {
  Box,
  FormControl,
  FormLabel,
  Input,
  Stack,
  Flex,
  Button,
  Text,
} from "@chakra-ui/react";
import { AuthContext } from "../../context";

const ChangePassword = () => {
  const { changePassword } = useContext(AuthContext);
  const [oldPassword, setOldPassword] = useState(""); // Added state for old password
  const [newPassword, setNewPassword] = useState("");
  const [confirmNewPassword, setConfirmNewPassword] = useState("");
  const [error, setError] = useState("");

  const handleChangePassword = async (e) => {
    e.preventDefault();
    setError("");

    try {
      await changePassword(oldPassword,newPassword, confirmNewPassword);
      setOldPassword("");
      setNewPassword("");
      setConfirmNewPassword("");
    } catch (err) {
      // Error handling is already done in changePassword function
    } finally {
      // Optional: handle any final logic
    }
  };

  return (
    <Box p={5} borderRadius="12px" background="white">
      <form onSubmit={handleChangePassword}>
        <Stack spacing={4}>
          <FormControl isRequired>
            <FormLabel>Current Password</FormLabel>
            <Input
              type="password"
              value={oldPassword}
              onChange={(e) => setOldPassword(e.target.value)}
              borderRadius="8px"
              border="1px solid #E5E6E6"
              background="white"
              h={12}
            />
          </FormControl>
          <FormControl isRequired>
            <FormLabel>New Password</FormLabel>
            <Input
              type="password"
              value={newPassword}
              onChange={(e) => setNewPassword(e.target.value)}
              borderRadius="8px"
              border="1px solid #E5E6E6"
              background="white"
              h={12}
            />
          </FormControl>
          <FormControl isRequired>
            <FormLabel>Confirm New Password</FormLabel>
            <Input
              type="password"
              value={confirmNewPassword}
              onChange={(e) => setConfirmNewPassword(e.target.value)}
              borderRadius="8px"
              border="1px solid #E5E6E6"
              background="white"
              h={12}
            />
          </FormControl>
          {error && (
            <Text color="red" mt={2}>
              {error}
            </Text>
          )}
        </Stack>
        <Flex justifyContent="flex-end" mt={4} px={4}>
          <Button
            type="submit"
            sx={{
              borderRadius: "8px",
              background: "#016DDA",
              color: "white",
              textAlign: "center",
              fontFamily: "Segoe UI",
              fontSize: "14px",
              fontWeight: 600,
              _hover: { background: "#004AA4" },
            }}
          >
            Change Password
          </Button>
        </Flex>
      </form>
    </Box>
  );
};

export default ChangePassword;