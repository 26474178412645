import React, { useState } from "react";
import {
  Box,
  Button,
  FormControl,
  Input,
  Image,
  Text,
  Flex,
  Heading,
} from "@chakra-ui/react";
import { useNavigate } from "react-router-dom";

const IDCheck = ({ uploadIdentification, fetchUserData, loginToken }) => {
  const [frontID, setFrontID] = useState(null);
  const [backID, setBackID] = useState(null);
  const [photoWithID, setPhotoWithID] = useState(null);
  const navigate = useNavigate();

  const IMAGE_HEIGHT = "110px";

  const handleFrontIDChange = (e) => {
    const file = e.target.files[0];
    if (file) {
      setFrontID(file);
    }
  };

  const handleBackIDChange = (e) => {
    const file = e.target.files[0];
    if (file) {
      setBackID(file);
    }
  };

  const handlePhotoWithIDChange = (e) => {
    const file = e.target.files[0];
    if (file) {
      setPhotoWithID(file);
    }
  };

  const sendIDCheck = async () => {
    try {
      await uploadIdentification(frontID, backID, photoWithID);
    } catch (error) {
      // Handle error
    }
  };

  const handleSkip = () => {
    window.location.reload();
  };

  return (
    <Box py={5} px={64}>
      <Heading fontSize={34} mb={4}>
        ID Verification
      </Heading>
      <Text fontSize={16} color="#707887" mb={4}>
        To ensure a secure and trustworthy platform, we require a quick ID
        verification. This step helps protect your profile and ensures
        credibility for employers.
      </Text>
      <Text fontSize={16} color="#707887" mt={4}>
        Please upload:
      </Text>
      <Text fontSize={16} color="#707887" ml={4} mt={2}>
        1. A clear photo of your government-issued ID (passport, national ID, or
        driver’s license).
      </Text>
      <Text fontSize={16} color="#707887" ml={4}>
        2. A selfie holding your ID for additional verification.
      </Text>
      <Text fontSize={16} color="#707887" mt={4}>
        Your information will be securely stored and only used for verification
        purposes, in compliance with our Privacy Policy.
      </Text>

      <Flex justify="space-between" width="100%" gap={6} mt={9}>
        {/* Front ID Upload */}
        <FormControl isRequired>
          <Flex align="center" mb={4} width="100%" direction="column">
            <Input
              type="file"
              accept="image/*"
              onChange={handleFrontIDChange}
              display="none"
              id="front-id-input"
            />
            <Button
              onClick={() => document.getElementById("front-id-input").click()}
              background="transparent"
              border="1px solid #016DDA"
              color="#016DDA"
              width="100%"
              borderRadius="8px"
              py={6}
            >
              Upload Front
            </Button>

            <Image
              src={
                frontID ? URL.createObjectURL(frontID) : "images/front_id.png"
              }
              alt="Front of ID"
              width="100%"
              height={IMAGE_HEIGHT}
              objectFit="cover"
              mt={6}
            />
          </Flex>
        </FormControl>

        {/* Back ID Upload */}
        <FormControl isRequired>
          <Flex align="center" mb={4} width="100%" direction="column">
            <Input
              type="file"
              accept="image/*"
              onChange={handleBackIDChange}
              display="none"
              id="back-id-input"
            />
            <Button
              onClick={() => document.getElementById("back-id-input").click()}
              background="transparent"
              border="1px solid #016DDA"
              color="#016DDA"
              width="100%"
              borderRadius="8px"
              py={6}
            >
              Upload Back
            </Button>
            <Image
              src={backID ? URL.createObjectURL(backID) : "images/back_id.png"}
              alt="Back of ID"
              width="100%"
              height={IMAGE_HEIGHT}
              objectFit="cover"
              mt={6}
            />
          </Flex>
        </FormControl>

        {/* Photo with ID Upload */}
        <FormControl isRequired>
          <Flex align="center" width="100%" direction="column">
            <Input
              type="file"
              accept="image/*"
              onChange={handlePhotoWithIDChange}
              display="none"
              id="photo-with-id-input"
            />
            <Button
              onClick={() =>
                document.getElementById("photo-with-id-input").click()
              }
              background="transparent"
              border="1px solid #016DDA"
              color="#016DDA"
              width="100%"
              borderRadius="8px"
              py={6}
            >
              Upload Photo with ID
            </Button>
            {photoWithID ? (
              <Image
                src={URL.createObjectURL(photoWithID)}
                alt="Photo with ID"
                width="100%"
                height={IMAGE_HEIGHT}
                objectFit="cover"
                mt={6}
              />
            ) : (
              <Box
                width="100%"
                height={IMAGE_HEIGHT}
                mt={6}
                display="flex"
                alignItems="center"
                justifyContent="center"
                border="1px dashed #016DDA"
                borderRadius="8px"
              >
                <Text color="#016DDA">Photo with ID</Text>
              </Box>
            )}
          </Flex>
        </FormControl>
      </Flex>

      <Flex justifyContent="flex-end" mt={6} px={0}>
        <Flex gap={4}>
          <Button
            px={6}
            sx={{
              borderRadius: "8px",
              background: "#016DDA",
              color: "white",
              textAlign: "center",
              fontFamily: "Segoe UI",
              fontSize: "14px",
              fontWeight: 600,
              _hover: { background: "#004AA4" },
            }}
            onClick={() => {
              if (frontID && backID && photoWithID) {
                sendIDCheck();
              }
            }}
          >
            Upload
          </Button>
          <Button
            px={6}
            sx={{
              borderRadius: "8px",
              background: "#E2E8F0",
              color: "#49424D",
              textAlign: "center",
              fontFamily: "Segoe UI",
              fontSize: "14px",
              fontWeight: 600,
              _hover: { background: "#CBD5E0" },
            }}
            onClick={handleSkip}
          >
            Skip
          </Button>
        </Flex>
      </Flex>
    </Box>
  );
};

export default IDCheck;
