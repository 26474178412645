import { useEffect, useContext, useState } from "react";
import {
  Box,
  FormControl,
  Input,
  Stack,
  HStack,
  Menu,
  MenuButton,
  MenuList,
  MenuItem,
  Button,
  Text,
  Heading,
  Image,
  Flex,
  IconButton,
  InputGroup,
  InputRightElement,
  Link,
} from "@chakra-ui/react";
import { ChevronDownIcon } from "@chakra-ui/icons";
import ReactCountryFlag from "react-country-flag";
import { useForm } from "react-hook-form";
import "react-international-phone/style.css";
import { countriesByName, countriesByCode } from "../../data/countries";
import notify from "../../utils/notify";
import validator from "email-validator";
import { AuthContext } from "../../context";
import { useNavigate, Link as RouterLink } from "react-router-dom";
import { ViewIcon, ViewOffIcon } from "@chakra-ui/icons";

const Signup = () => {
  const { signup } = useContext(AuthContext);
  const navigate = useNavigate();
  const {
    register,
    handleSubmit,
    watch,
    setValue,
    formState: { errors },
  } = useForm({
    defaultValues: {
      email: "",
      password: "",
      repeatedPassword: "",
      firstName: "",
      lastName: "",
      address: "",
      zipCode: "",
      country: "United States",
      code: "+1",
      phoneNumber: "",
      dateOfBirth: "",
    },
  });
  const [showPassword, setShowPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);

  const togglePasswordVisibility = () => {
    setShowPassword(!showPassword);
  };

  const toggleConfirmPasswordVisibility = () => {
    setShowConfirmPassword(!showConfirmPassword);
  };

  const country = watch("country");

  useEffect(() => {
    setValue("country", "United States");
    setValue("code", "+1");
  }, [setValue]);

  const onSubmit = (data) => {
    // Destructure necessary fields from data
    const {
      email,
      password,
      repeatedPassword,
      firstName,
      lastName,
      address,
      zipCode,
      phoneNumber,
      dateOfBirth,
      code,
      country,
    } = data;

    // Validate required fields
    if (
      !email ||
      !password ||
      !repeatedPassword ||
      !firstName ||
      !lastName ||
      !address ||
      !zipCode ||
      !phoneNumber ||
      !dateOfBirth ||
      !code ||
      !country
    ) {
      return notify("error", "Please fill in all required fields.");
    }

    const fullDataOfCountry = countriesByCode.find(
      (currentCountry) => currentCountry.name === country
    );
    // This is because USA and Canada have the same dial_code
    if (
      fullDataOfCountry.name === "United States" ||
      fullDataOfCountry.name === "Canada"
    ) {
      if (code !== "+1") {
        return notify(
          "error",
          "Your phone number must me from your country of residency"
        );
      }
    } else {
      if (fullDataOfCountry.dial_code !== code) {
        return notify(
          "error",
          "Your phone number must me from your country of residency"
        );
      }
    }

    const fullPhoneNumber = code.concat(phoneNumber);

    // Password mismatch check
    if (password !== repeatedPassword) {
      return notify(
        "error",
        "Passwords do not match. Please check and try again."
      );
    }

    // Email validation
    if (!validator.validate(email)) {
      return notify(
        "error",
        "The email is not valid. Please enter a valid email."
      );
    }

    if (password.length < 8) {
      return notify("error", "Password must be at least 8 characters long.");
    }

    // Zipcode validation
    if (!isValidZipcode(zipCode)) {
      return notify("error", "Please enter a valid zip code.");
    }

    // Date of birth validation
    if (!isValidDate(dateOfBirth)) {
      return notify("error", "Please enter a valid birthday (in the past).");
    }

    // Age check (must be at least 18 years old)
    if (!isOldEnough(dateOfBirth)) {
      return notify("error", "You must be at least 18 years old to sign up.");
    }

    // Phone number validation
    if (!isValidPhoneNumber(fullPhoneNumber)) {
      return notify(
        "error",
        "Please enter a valid phone number starting with '+' followed by the country code."
      );
    }

    // Prepare data to be sent to the API (exclude 'repeatedPassword')
    const signupData = {
      email,
      password,
      firstName,
      lastName,
      address,
      zipCode,
      phoneNumber: fullPhoneNumber,
      dateOfBirth,
      country: fullDataOfCountry.code,
    };

    // Attempt signup using .then() and .catch()
    signup(signupData)
      .then(() => {
        navigate(`/signup-confirmation/${email}`);
      })
      .catch((error) => {
        // Handle specific signup errors if available
        const errorMessage =
          error.response && error.response.data && error.response.data.message
            ? error.response.data.message
            : "An error occurred during signup. Please try again later.";
        notify("error", errorMessage);
      });
  };

  const isValidDate = (dateString) => {
    if (!dateString) return false;

    const date = new Date(dateString);

    if (isNaN(date.getTime())) return false;

    const today = new Date();
    return date <= today;
  };

  const getImageUsingName = (name) => {
    const country = countriesByName.find(
      (currentCountry) => currentCountry.name === name
    );
    return country.code;
  };

  const isValidPhoneNumber = (phoneNumber) => {
    const phonePattern = /^\+\d{1,3}\s?\d{10}$/; // Matches: +<country_code> <10_digit_number>
    return phonePattern.test(phoneNumber);
  };

  const isOldEnough = (dateOfBirth) => {
    if (!dateOfBirth) return false;

    const birthDate = new Date(dateOfBirth);
    const today = new Date();
    const age = today.getFullYear() - birthDate.getFullYear();

    if (
      today.getMonth() < birthDate.getMonth() ||
      (today.getMonth() === birthDate.getMonth() &&
        today.getDate() < birthDate.getDate())
    ) {
      return age >= 18 ? true : false;
    }

    return age >= 18;
  };

  const isValidZipcode = (zipcode) => {
    if (!zipcode) {
      zipcode = "";
    }
    const trimmedZipcode = zipcode.trim();
    const zipCodePattern = /^[A-Za-z0-9\s\-]{3,10}$/;
    return zipCodePattern.test(trimmedZipcode);
  };

  const getImageUsingCode = (dial_code) => {
    const country = countriesByCode.find(
      (currentCountry) => currentCountry.dial_code == dial_code
    );
    return country.code;
  };

  return (
    <Flex height="100vh">
      <Box width="30%" display={{ base: "none", md: "block" }}>
        <Box
          height="100vh"
          width="100%"
          backgroundImage="url('/images/sidebar.jpeg')"
          backgroundSize="cover"
          backgroundPosition="center"
          backgroundRepeat="no-repeat"
          backgroundColor="#0065F2"
          backgroundBlendMode="multiply"
          display="flex"
          alignItems="center"
          justifyContent="center"
        >
          <Flex alignItems="center">
            <Image src="/images/logo.png" alt="Logo" boxSize="50px" mr={2} />
            <Text fontSize="xl" color="white">
              MyTeamAbroad
            </Text>
          </Flex>
        </Box>
      </Box>
      <Flex
        width={{ base: "100%", md: "70%" }}
        align="center"
        justify="center"
        bg="white"
        p={4}
      >
        <Box width="100%" maxWidth="800px" bg="white" borderRadius="md">
          <Box p={5}>
            <Heading fontSize={40}>Sign Up</Heading>
            <Text color="#707887" mt={2} mb={8}>
              Join our global network to explore exciting career opportunities
              and showcase your skills to top recruiters worldwide. <br />{" "}
              <br />
              By signing up, you agree to our{" "}
              <Link color="#016DDA">terms of service</Link> and{" "}
              <Link color="#016DDA">privacy policy</Link>. Your information will
              be kept secure and used only to connect you with potential
              employers and relevant opportunities.
            </Text>
            <Stack spacing={4} as="form" onSubmit={handleSubmit(onSubmit)}>
              <FormControl isRequired>
                <Input
                  {...register("email", { required: true })}
                  placeholder="Email"
                  borderRadius="8px"
                  border="1px solid #E5E6E6"
                  background="white"
                  h={12}
                />
                {errors.email && <Text color="red">Email is required</Text>}
              </FormControl>

              <HStack spacing={4}>
                <FormControl isRequired>
                  <InputGroup>
                    <Input
                      {...register("password", { required: true })}
                      type={showPassword ? "text" : "password"} // Toggle between text and password
                      placeholder="Password"
                      borderRadius="8px"
                      border="1px solid #E5E6E6"
                      background="white"
                      h={12}
                    />
                    <InputRightElement mt={1}>
                      <IconButton
                        onClick={togglePasswordVisibility}
                        icon={showPassword ? <ViewOffIcon /> : <ViewIcon />}
                        aria-label="Toggle Password Visibility"
                        variant="link"
                        size="sm" // Adjust size as needed
                      />
                    </InputRightElement>
                  </InputGroup>
                  {errors.password && (
                    <Text color="red">Password is required</Text>
                  )}
                </FormControl>

                <FormControl isRequired>
                  <InputGroup>
                    <Input
                      {...register("repeatedPassword", { required: true })}
                      type={showConfirmPassword ? "text" : "password"} // Toggle between text and password
                      placeholder="Repeat Password"
                      borderRadius="8px"
                      border="1px solid #E5E6E6"
                      background="white"
                      h={12}
                    />
                    <InputRightElement mt={1}>
                      <IconButton
                        onClick={toggleConfirmPasswordVisibility}
                        icon={
                          showConfirmPassword ? <ViewOffIcon /> : <ViewIcon />
                        }
                        aria-label="Toggle Password Visibility"
                        variant="link"
                        size="sm" // Adjust size as needed
                      />
                    </InputRightElement>
                  </InputGroup>
                  {errors.repeatedPassword && (
                    <Text color="red">Repeat Password is required</Text>
                  )}
                </FormControl>
              </HStack>

              <HStack spacing={4}>
                <FormControl isRequired>
                  <Input
                    {...register("firstName", { required: true })}
                    placeholder="First Name"
                    borderRadius="8px"
                    border="1px solid #E5E6E6"
                    background="white"
                    h={12}
                  />
                </FormControl>

                <FormControl isRequired>
                  <Input
                    {...register("lastName", { required: true })}
                    placeholder="Last Name"
                    borderRadius="8px"
                    border="1px solid #E5E6E6"
                    background="white"
                    h={12}
                  />
                </FormControl>
              </HStack>

              <FormControl isRequired>
                <Input
                  {...register("address", { required: true })}
                  placeholder="Address"
                  borderRadius="8px"
                  border="1px solid #E5E6E6"
                  background="white"
                  h={12}
                />
              </FormControl>

              <HStack spacing={4}>
                <FormControl isRequired>
                  <Input
                    {...register("zipCode", { required: true })}
                    placeholder="Zip Code"
                    borderRadius="8px"
                    border="1px solid #E5E6E6"
                    background="white"
                    h={12}
                  />
                </FormControl>

                <FormControl isRequired>
                  <Menu>
                    <MenuButton
                      as={Button}
                      borderRadius="8px"
                      border="1px solid #E5E6E6"
                      background="white"
                      h={12}
                      pr={16} // This padding will not affect the icon now
                      width="100%"
                      position="relative" // Needed for absolute positioning of the icon
                      _hover={{
                        background: "white",
                        border: "1px solid #E5E6E6",
                      }}
                      _active={{
                        background: "white",
                        border: "1px solid #E5E6E6",
                      }}
                    >
                      <Box display="flex" alignItems="center">
                        {country ? (
                          <Flex direction="row" fontWeight={400}>
                            <ReactCountryFlag
                              countryCode={getImageUsingName(country)}
                              svg
                              style={{
                                width: "1.5em",
                                height: "1.5em",
                                marginRight: "8px",
                              }}
                            />
                            {country}
                          </Flex>
                        ) : (
                          <>Select country</>
                        )}
                      </Box>
                      <ChevronDownIcon
                        position="absolute"
                        right="12px"
                        top="50%"
                        transform="translateY(-50%)"
                      />
                    </MenuButton>
                    <MenuList maxH="200px" overflowY="auto">
                      {countriesByName.map((currentCountry) => (
                        <MenuItem
                          key={currentCountry.name}
                          display="flex"
                          alignItems="center"
                          onClick={() => {
                            setValue("country", currentCountry.name);
                            setValue("code", currentCountry.dial_code)
                          }}
                        >
                          <ReactCountryFlag
                            countryCode={currentCountry.code}
                            svg
                            style={{
                              width: "1.5em",
                              height: "1.5em",
                              marginRight: "8px",
                            }}
                          />
                          <Text fontWeight={400}>
                            {`${currentCountry.name}`}
                          </Text>
                        </MenuItem>
                      ))}
                    </MenuList>
                  </Menu>
                </FormControl>
              </HStack>

              <FormControl required>
                <Flex direction="row" gap={2} width="100%">
                  <Menu>
                    <MenuButton
                      as={Button}
                      border="1px solid #E5E6E6"
                      background="white"
                      h={12}
                      width="30%"
                      textAlign="left"
                      position="relative" // Add relative positioning for absolute icon
                      pr={16} // Add padding to the right to make space for the icon
                      _hover={{
                        background: "white",
                        border: "1px solid #E5E6E6",
                      }}
                      _active={{
                        background: "white",
                        border: "1px solid #E5E6E6",
                      }}
                    >
                      <Box display="flex" alignItems="center">
                        {watch("code") ? (
                          <Flex direction="row" fontWeight={400}>
                            <ReactCountryFlag
                              countryCode={getImageUsingCode(watch("code"))}
                              svg
                              style={{
                                width: "1.5em",
                                height: "1.5em",
                                marginRight: "8px",
                              }}
                            />
                            {watch("code")}
                          </Flex>
                        ) : (
                          <Box>Code</Box>
                        )}
                      </Box>
                      <ChevronDownIcon
                        position="absolute"
                        right="12px"
                        top="50%"
                        transform="translateY(-50%)" // Center the icon vertically
                      />
                    </MenuButton>

                    <MenuList maxH="200px" overflowY="auto">
                      {countriesByName.map((country) => (
                        <MenuItem
                          key={country.code}
                          display="flex"
                          alignItems="center"
                          onClick={() => {
                            setValue("country", country.name);
                            setValue("code", country.dial_code)
                          }}
                        >
                          <Box marginRight="10px">
                            <Image
                              src={`/images/flags/${country.code}.svg`}
                              alt={`${country.name} flag`}
                              style={{ width: "20px", height: "auto" }}
                            />
                          </Box>
                            {country.code} {country.dial_code}
                        </MenuItem>
                      ))}
                    </MenuList>
                  </Menu>

                  <Input
                    name="phoneNumber"
                    type="tel"
                    placeholder="Phone number"
                    borderRadius="8px"
                    border="1px solid #E5E6E6"
                    background="white"
                    h={12}
                    width="70%" // Allow Input to take the remaining space
                    {...register("phoneNumber", { required: true })} // Register the phone number field
                  />
                </Flex>
              </FormControl>
              <FormControl isRequired>
                <Input
                  {...register("dateOfBirth", { required: true })}
                  type="date"
                  borderRadius="8px"
                  border="1px solid #E5E6E6"
                  background="white"
                  h={12}
                />
              </FormControl>

              <Flex justifyContent="space-between" mt={4} px={4}>
                <Button
                  as={RouterLink}
                  to="/"
                  px={6}
                  sx={{
                    borderRadius: "8px",
                    background: "#DCEBFA",
                    color: "#016DDA",
                    textAlign: "center",
                    fontFamily: "Segoe UI",
                    fontSize: "14px",
                    fontWeight: 600,
                    _hover: { background: "#B9DBF5", color: "#005BB5" },
                  }}
                >
                  Login
                </Button>
                <Button
                  px={6}
                  type="submit"
                  sx={{
                    borderRadius: "8px",
                    background: "#016DDA",
                    color: "white",
                    textAlign: "center",
                    fontFamily: "Segoe UI",
                    fontSize: "14px",
                    fontWeight: 600,
                    _hover: { background: "#004AA4" },
                  }}
                >
                  Sign Up
                </Button>
              </Flex>
            </Stack>
          </Box>
        </Box>
      </Flex>
    </Flex>
  );
};

export default Signup;
