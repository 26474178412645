import React, { useState, useContext } from "react";
import {
  Box,
  Flex,
  FormControl,
  Input,
  Button,
  Stack,
  Image,
  Text,
  InputGroup,
  InputRightElement,
  IconButton,
} from "@chakra-ui/react";
import { useForm } from "react-hook-form";
import { useNavigate, useParams } from "react-router-dom";
import { AuthContext } from "../../context";
import { ViewIcon, ViewOffIcon } from "@chakra-ui/icons";

const PasswordRecoveryConfirm = () => {
  const { confirmPasswordRecovery } = useContext(AuthContext);
  const navigate = useNavigate();
  const { token } = useParams(); // Get token from URL
  const [showPassword, setShowPassword] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [message, setMessage] = useState("");

  const togglePasswordVisibility = () => {
    setShowPassword(!showPassword);
  };

  const {
    register,
    handleSubmit,
    formState: { errors },
    watch,
  } = useForm();

  const onSubmit = async (data) => {
    setIsSubmitting(true);
    setMessage("");
    try {
      await confirmPasswordRecovery(token, data.password);
      setMessage("Your password has been reset successfully.");
      navigate("/");
    } catch (err) {
      console.error(err);
      setMessage("An error occurred while resetting your password.");
    } finally {
      setIsSubmitting(false);
    }
  };

  return (
    <Flex height="100vh">
      {/* Left Sidebar */}
      <Box width="30%" display={{ base: "none", md: "block" }}>
        <Box
          height="100vh"
          width="100%"
          backgroundImage="url('/images/sidebar.jpeg')"
          backgroundSize="cover"
          backgroundPosition="center"
          backgroundRepeat="no-repeat"
          backgroundColor="#0065F2"
          backgroundBlendMode="multiply"
          display="flex"
          alignItems="center"
          justifyContent="center"
        >
          <Flex alignItems="center">
            <Image src="/images/logo.png" alt="Logo" boxSize="50px" mr={2} />
            <Text fontSize="xl" color="white">
              MyTeamAbroad
            </Text>
          </Flex>
        </Box>
      </Box>
      {/* Main Content */}
      <Flex
        width={{ base: "100%", md: "70%" }}
        align="center"
        justify="center"
        bg="white"
        p={8}
      >
        <Box width="100%" maxWidth="600px" p={6} bg="white" borderRadius="md">
          <Flex direction="column" mb={4}>
            <Text fontSize="2xl" fontWeight="bold">
              Reset Password
            </Text>
            <Text fontSize="sm" color="gray.500">
              Enter your new password
            </Text>
          </Flex>
          <form onSubmit={handleSubmit(onSubmit)}>
            <Box>
              <Stack spacing={4}>
                <FormControl id="password" isRequired>
                  <InputGroup>
                    <Input
                      type={showPassword ? "text" : "password"}
                      placeholder="New Password"
                      borderRadius="8px"
                      border="1px solid #E5E6E6"
                      background="white"
                      h={12}
                      {...register("password", {
                        required: "Password is required",
                        minLength: {
                          value: 8,
                          message: "Password must be at least 8 characters",
                        },
                      })}
                    />
                    <InputRightElement mt={1}>
                      <IconButton
                        onClick={togglePasswordVisibility}
                        icon={showPassword ? <ViewOffIcon /> : <ViewIcon />}
                        aria-label="Toggle Password Visibility"
                        variant="link"
                        size="sm"
                      />
                    </InputRightElement>
                  </InputGroup>
                  {errors.password && (
                    <Text color="red">{errors.password.message}</Text>
                  )}
                </FormControl>
                <FormControl id="confirmPassword" isRequired>
                  <InputGroup>
                    <Input
                      type={showPassword ? "text" : "password"}
                      placeholder="Confirm Password"
                      borderRadius="8px"
                      border="1px solid #E5E6E6"
                      background="white"
                      h={12}
                      {...register("confirmPassword", {
                        required: "Please confirm your password",
                        validate: (value) =>
                          value === watch("password") || "Passwords do not match",
                      })}
                    />
                    <InputRightElement mt={1}>
                      <IconButton
                        onClick={togglePasswordVisibility}
                        icon={showPassword ? <ViewOffIcon /> : <ViewIcon />}
                        aria-label="Toggle Password Visibility"
                        variant="link"
                        size="sm"
                      />
                    </InputRightElement>
                  </InputGroup>
                  {errors.confirmPassword && (
                    <Text color="red">{errors.confirmPassword.message}</Text>
                  )}
                </FormControl>
              </Stack>
            </Box>
            <Flex justifyContent="flex-end" mt={8}>
              <Button
                type="submit"
                px={6}
                isLoading={isSubmitting}
                sx={{
                  borderRadius: "8px",
                  background: "#016DDA",
                  color: "#FFF",
                  textAlign: "center",
                  fontFamily: "Segoe UI",
                  fontSize: "14px",
                  fontStyle: "normal",
                  fontWeight: 600,
                  lineHeight: "normal",
                  _hover: {
                    background: "#005BB5",
                  },
                }}
              >
                Reset Password
              </Button>
            </Flex>
          </form>
          {message && <Text mt={4}>{message}</Text>}
        </Box>
      </Flex>
    </Flex>
  );
};

export default PasswordRecoveryConfirm;