import React, { useState, useContext } from "react";
import {
  Box,
  Flex,
  FormControl,
  Input,
  Button,
  Stack,
  Image,
  Text
} from "@chakra-ui/react";
import { useForm } from "react-hook-form";
import { useNavigate } from "react-router-dom";
import { AuthContext } from "../../context";

const PasswordRecoveryRequest = () => {
  const { requestPasswordRecovery } = useContext(AuthContext);
  const [message, setMessage] = useState("");
  const [isSubmitting, setIsSubmitting] = useState(false);
  const navigate = useNavigate();

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm();

  const onSubmit = async (data) => {
    setIsSubmitting(true);
    setMessage("");
    try {
      await requestPasswordRecovery(data.email);
      setMessage("If the email exists, a recovery link has been sent.");
    } catch (err) {
      setMessage("An error occurred while requesting password recovery.");
    } finally {
      setIsSubmitting(false);
      navigate("/");
    }
  };

  return (
    <Flex height="100vh">
      {/* Left Sidebar */}
      <Box width="30%" display={{ base: "none", md: "block" }}>
        <Box
          height="100vh"
          width="100%"
          backgroundImage="url('/images/sidebar.jpeg')"
          backgroundSize="cover"
          backgroundPosition="center"
          backgroundRepeat="no-repeat"
          backgroundColor="#0065F2"
          backgroundBlendMode="multiply"
          display="flex"
          alignItems="center"
          justifyContent="center"
        >
          <Flex alignItems="center">
            <Image src="/images/logo.png" alt="Logo" boxSize="50px" mr={2} />
            <Text fontSize="xl" color="white">MyTeamAbroad</Text>
          </Flex>
        </Box>
      </Box>
      {/* Main Content */}
      <Flex
        width={{ base: "100%", md: "70%" }}
        align="center"
        justify="center"
        bg="white"
        p={8}
      >
        <Box width="100%" maxWidth="600px" p={6} bg="white" borderRadius="md">
          <Flex direction="column" mb={4}>
            <Text fontSize="2xl" fontWeight="bold">Password Recovery</Text>
            <Text fontSize="sm" color="gray.500">Enter your email to reset your password</Text>
          </Flex>
          <form onSubmit={handleSubmit(onSubmit)}>
            <Box>
              <Stack spacing={4}>
                <FormControl id="email" isRequired>
                  <Input
                    type="email"
                    placeholder="Email"
                    borderRadius="8px"
                    border="1px solid #E5E6E6"
                    background="white"
                    h={12}
                    {...register("email", { required: "Email is required" })}
                  />
                  {errors.email && <Text color="red">{errors.email.message}</Text>}
                </FormControl>
              </Stack>
            </Box>
            <Flex justifyContent="space-between" mt={8}>
              <Button
                type="submit"
                px={6}
                isLoading={isSubmitting}
                sx={{
                  borderRadius: "8px",
                  background: "#016DDA",
                  color: "#FFF",
                  textAlign: "center",
                  fontFamily: "Segoe UI",
                  fontSize: "14px",
                  fontStyle: "normal",
                  fontWeight: 600,
                  lineHeight: "normal",
                  _hover: {
                    background: "#005BB5",
                  },
                }}
              >
                Send Recovery Email
              </Button>
            </Flex>
          </form>
        </Box>
      </Flex>
    </Flex>
  );
};

export default PasswordRecoveryRequest;