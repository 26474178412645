import React, { useContext } from "react";
import { Box, Heading, Text } from "@chakra-ui/react";
import { AuthContext } from "../../context";

const HeaderCard = () => {
  const { userData } = useContext(AuthContext);

  return (
    <Box borderRadius="12px" background="white" p={6} textAlign="left" mb={4}>
      <Heading fontSize="24px" color="#49424D" fontFamily="Segoe UI">
        {userData ? userData.firstName : "Test"}{" "}
        {userData ? userData.lastName : "Test"}
      </Heading>
      <Text color="#707887" mt={2} fontFamily="Segoe UI">
        Welcome to your settings! Here, you can manage your account, customize
        your preferences, and ensure everything is tailored to your needs.
      </Text>
    </Box>
  );
};

export default HeaderCard;
