import React, { useState } from "react";
import {
  Box,
  FormControl,
  Input,
  Button,
  Flex,
  Heading,
  Text,
  UnorderedList,
  ListItem,
} from "@chakra-ui/react";
import notify from "../../../utils/notify"; // Import your notify function

const CVAdding = ({ handleNext, uploadCv, createCvFromLinkedIn }) => {
  const [linkedInLink, setLinkedInLink] = useState(""); // Store the LinkedIn link
  const [cvFile, setCvFile] = useState(null); // Store the uploaded CV file
  const [isError, setIsError] = useState(false); // For validation
  const [isUploading, setIsUploading] = useState(false); // Track upload status

  const sendCV = async () => {
    if (!linkedInLink) {
      setIsError(true);
      notify("error", "LinkedIn URL is required");
      return;
    }

    setIsUploading(true);
    try {
      await createCvFromLinkedIn(linkedInLink);
      notify("success", "CV created successfully");
      handleNext();
    } catch (error) {
      notify("error", "Failed to create CV from LinkedIn");
    } finally {
      setIsUploading(false);
    }
  };

  return (
    <Box py={5} px={64}>
      <Heading fontSize={34} size="lg" mb={4}>
        MTA CV
      </Heading>
      <Text fontSize={16} color="#707887">
        Your CV will be built based on the information from your LinkedIn
        profile. To ensure it represents you accurately and professionally:
      </Text>
      <UnorderedList mt={2} mb={4} spacing={2} color="#707887" fontSize={16}>
        <ListItem>
          <Text as="span" fontWeight="bold">
            Make sure your LinkedIn profile is up-to-date, including:
          </Text>
          <UnorderedList mt={1} spacing={1} ml={4}>
            <ListItem>
              An accurate and clear headline (e.g., "Software Engineer | React &
              Node.js Specialist").
            </ListItem>
            <ListItem>
              Relevant work experience with proper descriptions.
            </ListItem>
            <ListItem>Your education, certifications, and skills.</ListItem>
          </UnorderedList>
        </ListItem>
        <ListItem fontWeight="bold">
          Important: Employers value a polished profile, so take a moment to
          review and refine your LinkedIn before adding the link.
        </ListItem>
      </UnorderedList>
      <Text fontSize={16} color="#707887" mb={4}>
        Enter your LinkedIn URL below to get started:
      </Text>
      <FormControl isInvalid={isError} isRequired>
        <Input
          type="url"
          placeholder="LinkedIn URL"
          value={linkedInLink}
          onChange={(e) => setLinkedInLink(e.target.value)}
          borderRadius="8px"
          py={6}
          px={4}
          mt={9}
        />
      </FormControl>
      <Flex justifyContent="flex-end" mt={10} px={0}>
        <Button
          px={6}
          sx={{
            borderRadius: "8px",
            background: "#016DDA",
            color: "white",
            textAlign: "center",
            fontFamily: "Segoe UI",
            fontSize: "14px",
            fontWeight: 600,
            _hover: { background: "#004AA4" },
          }}
          onClick={sendCV}
          isLoading={isUploading}
        >
          Authenticate
        </Button>
      </Flex>
    </Box>
  );
};

export default CVAdding;
