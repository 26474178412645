import { useContext } from "react";
import {
  BrowserRouter as Router,
  Routes,
  Route,
  Navigate,
} from "react-router-dom";
import { ChakraProvider } from "@chakra-ui/react";
import Login from "./pages/Login";
import Signup from "./pages/Signup";
import SignupConfirmation from "./pages/SignupConfirmation";
import VerifyEmail from "./pages/VerifyEmail";
import Home from "./pages/Home";
import LoginProcess from "./pages/LoginProcess";
import HomeLayout from "./pages/HomeLayout";
import { AuthContext } from "./context";
import PersonalInformation from "./pages/PersonalInformation";
import SettingsLayout from "./pages/SettingsLayout";
import Notifications from "./pages/Notifications";
import ChangePassword from "./pages/ChangePassword";
import DesiredSalary from "./pages/DesiredSalary";
import PasswordRecoveryRequest from "./pages/PasswordRecoveryRequest";
import PasswordRecoveryConfirm from "./pages/PasswordRecoveryConfirm";

function App() {
  const { loginToken, userData } = useContext(AuthContext);
  const role = localStorage.getItem("role");

  const getHomeRoute = () => {
    if (userData?.signupStatus === "completed") return "/home";
    return "/";
  };

  const publicRoutes = (
    <>
      <Route path="/" element={<Login />} />
      <Route path="/signup" element={<Signup />} />
      <Route
        path="/signup-confirmation/:email"
        element={<SignupConfirmation />}
      />
      <Route path="/verify-email/:email/:emailCode" element={<VerifyEmail />} />
      <Route path="/password-recovery" element={<PasswordRecoveryRequest />} />
      <Route
        path="/reset-password/:token"
        element={<PasswordRecoveryConfirm />}
      />
    </>
  );

  const userRoutes = (
    <>
      <Route path="/" element={<HomeLayout />}>
        <Route index element={<Home />} />
        <Route path="settings" element={<SettingsLayout />}>
          <Route index element={<PersonalInformation />} />
          <Route path="notifications" element={<Notifications />} />
          <Route path="desired-salary" element={<DesiredSalary />} />
          <Route path="change-password" element={<ChangePassword />} />
        </Route>
      </Route>
    </>
  );

  // Rutas de proceso de login
  const loginProcessRoutes = <Route path="/*" element={<LoginProcess />} />;

  return (
    <ChakraProvider>
      <Router>
        <Routes>
          {!loginToken && publicRoutes}
          {loginToken &&
            userData?.signupStatus === "completed" &&
            userRoutes}
          {loginToken &&
            !role &&
            userData?.signupStatus === "completed" &&
            userRoutes}
          {loginToken &&
            (!userData?.signupStatus ||
              userData?.signupStatus !== "completed") &&
            loginProcessRoutes}
          <Route path="*" element={<Navigate to={getHomeRoute()} replace />} />
        </Routes>
      </Router>
    </ChakraProvider>
  );
}

export default App;
