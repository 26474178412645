import React, { useContext, useEffect, useState } from "react";
import {
  Box,
  Progress,
  Text,
  Flex,
  Button,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  useDisclosure,
  FormControl,
  FormLabel,
  Input,
  Textarea,
} from "@chakra-ui/react";
import { CircularProgressbar } from "react-circular-progressbar";
import "react-circular-progressbar/dist/styles.css";
import { AuthContext } from "../../../context";
import { set } from "react-hook-form";

const CVDashboard = () => {
  const { getLinkedinInfo, updateJobInfo, getUserAssessments } =
    useContext(AuthContext);
  const [cvData, setCvData] = useState({
    jobExperiences: [],
    education: [],
    about: "",
  });
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [editableCvData, setEditableCvData] = useState(cvData);
  const [isSaving, setIsSaving] = useState(false);
  const [skills, setSkills] = useState([]);
  const [top5Skills, setTop5Skills] = useState([]);
  const [circlePercentage, setCirclePercentage] = useState(0);

  useEffect(() => {
    setEditableCvData(cvData);
  }, [cvData]);

  const handleChange = (field, value) => {
    setEditableCvData({ ...editableCvData, [field]: value });
  };

  const handleSubmit = async () => {
    setIsSaving(true);
    try {
      const { about, education, jobExperiences } = editableCvData;
      await updateJobInfo(about, education, jobExperiences);
      setCvData(editableCvData);
      onClose();
      // notify("success", "CV information updated successfully.");
    } catch (error) {
      console.log("Update Error:", error);
      // notify("error", "Failed to update CV information.");
    } finally {
      setIsSaving(false);
    }
  };

  useEffect(() => {
    const fetchData = async () => {
      try {
        const linkedinData = await getLinkedinInfo();
        console.log(linkedinData);
        if (linkedinData) {
          setCvData({
            about: linkedinData.about, // Ensure 'about' is a string
            jobExperiences: linkedinData.jobExperiences || [], // Ensure it's an array
            education: linkedinData.education || [], // Ensure it's an array
          });
        }
      } catch (error) {
        console.log("Error fetching LinkedIn info:", error);
      }
    };
    fetchData();
  }, [getLinkedinInfo]);

  useEffect(() => {
    const fetchSkills = async () => {
      try {
        const skillsList = await getUserAssessments();
        const skillsListFilteredByAttemps = skillsList.filter(
          (attemps) => attemps.attemps > 0 && attemps.status !== "pending"
        );
        const sortedSkills = skillsListFilteredByAttemps.sort(
          (a, b) => b.score - a.score
        );
        const top5Skills = sortedSkills.slice(0, 5);

        const totalScore = sortedSkills.reduce((acc, skill) => acc + skill.score, 0);
        const averageScore = totalScore / sortedSkills.length;
        setCirclePercentage(averageScore);
        setSkills(sortedSkills);
        setTop5Skills(top5Skills)
      } catch (error) {
        console.error("Error fetching skills:", error);
      }
    };

    fetchSkills();
  }, []);

  return (
    <Flex
      width="100%"
      flex="1"
      direction="column"
      backgroundColor="white"
      pb={5}
      px={5}
      pt={2}
      borderRadius="12px"
    >
      <Flex
        spacing={6}
        justifyContent="space-between"
        width="100%"
        direction="row"
        mb="72px"
      >
        <Flex direction="column" width="55%">
          <Text color="#49424D" fontSize={24} fontWeight={700} mb={5}>
            Overall
          </Text>
          <Box height="265px">
            {top5Skills.map((item, index) => (
              <Flex key={index} width="100%" direction="row" mb={4}>
                <Flex direction="column" width="100%">
                  <Text mb={1} color="#000F2B" fontSize={18}>
                    {item.name}
                  </Text>
                  <Progress
                    value={item.score}
                    size="md"
                    width="100%"
                    sx={{
                      borderRadius: "8px",
                      "& > div": {
                        borderRadius: "8px",
                        bg: "#016DDA",
                      },
                      bg: "gray.200",
                    }}
                  />
                </Flex>
                <Text mt={3} pt={1} ml={2} color="#000F2B" opacity={0.65}>
                  100
                </Text>
              </Flex>
            ))}
          </Box>
        </Flex>

        <Box height="200px" width="200px" pt={10}>
          <CircularProgressbar
            value={circlePercentage}
            text={`${circlePercentage}%`}
            color="black"
            styles={{
              path: {
                stroke: `#016DDA`,
                strokeLinecap: "round",
                transition: "stroke-dashoffset 0.5s ease 0s",
              },
              trail: {
                stroke: "#E5E6E6",
              },
              text: {
                fontSize: "18px",
                fontWeight: "500",
                fill: "black",
              },
            }}
          />
        </Box>
      </Flex>
      <Button
        borderRadius="8px"
        background="#016DDA"
        color="white"
        _hover={{ background: "#016DDA" }}
        fontWeight={400}
        mt={0}
        onClick={onOpen}
      >
        Edit CV Text
      </Button>

      {/* Edit CV Modal */}
      <Modal isOpen={isOpen} onClose={onClose} size="xl">
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>Edit CV</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            {/* About Section */}
            <FormControl mb={4}>
              <FormLabel>About</FormLabel>
              <Textarea
                value={editableCvData.about}
                onChange={(e) => handleChange("about", e.target.value)}
                placeholder="About yourself"
              />
            </FormControl>

            {/* Job Experiences */}
            <Box mb={4}>
              <FormLabel>Job Experiences</FormLabel>
              {editableCvData.jobExperiences.map((job, index) => (
                <Box
                  key={index}
                  mb={3}
                  p={3}
                  border="1px"
                  borderColor="gray.200"
                  borderRadius="8px"
                >
                  <Flex justifyContent="space-between" mb={2}>
                    <Text fontWeight="bold">Job {index + 1}</Text>
                    <Button
                      size="sm"
                      variant="outline"
                      colorScheme="blue"
                      onClick={() => {
                        const updatedJobs = [...editableCvData.jobExperiences];
                        updatedJobs.splice(index, 1);
                        handleChange("jobExperiences", updatedJobs);
                      }}
                    >
                      Remove
                    </Button>
                  </Flex>
                  <FormControl mb={2}>
                    <FormLabel>Company</FormLabel>
                    <Input
                      value={job.company}
                      onChange={(e) => {
                        const updatedJobs = [...editableCvData.jobExperiences];
                        updatedJobs[index].company = e.target.value;
                        handleChange("jobExperiences", updatedJobs);
                      }}
                      placeholder="Company"
                    />
                  </FormControl>
                  <FormControl mb={2}>
                    <FormLabel>Position</FormLabel>
                    <Input
                      value={job.position}
                      onChange={(e) => {
                        const updatedJobs = [...editableCvData.jobExperiences];
                        updatedJobs[index].position = e.target.value;
                        handleChange("jobExperiences", updatedJobs);
                      }}
                      placeholder="Position"
                    />
                  </FormControl>
                  <FormControl mb={2}>
                    <FormLabel>Description</FormLabel>
                    <Textarea
                      value={job.description}
                      onChange={(e) => {
                        const updatedJobs = [...editableCvData.jobExperiences];
                        updatedJobs[index].description = e.target.value;
                        handleChange("jobExperiences", updatedJobs);
                      }}
                      placeholder="Description"
                    />
                  </FormControl>
                  <Flex>
                    <FormControl mr={2}>
                      <FormLabel>Start Date</FormLabel>
                      <Input
                        type="date"
                        value={job.startDate}
                        onChange={(e) => {
                          const updatedJobs = [
                            ...editableCvData.jobExperiences,
                          ];
                          updatedJobs[index].startDate = e.target.value;
                          handleChange("jobExperiences", updatedJobs);
                        }}
                      />
                    </FormControl>
                    <FormControl>
                      <FormLabel>End Date</FormLabel>
                      <Input
                        type="date"
                        value={job.endDate}
                        onChange={(e) => {
                          const updatedJobs = [
                            ...editableCvData.jobExperiences,
                          ];
                          updatedJobs[index].endDate = e.target.value;
                          handleChange("jobExperiences", updatedJobs);
                        }}
                      />
                    </FormControl>
                  </Flex>
                </Box>
              ))}
              <Button
                size="sm"
                background="#016DDA"
                color="white"
                _hover={{ background: "#016DDA" }}
                onClick={() => {
                  const newJob = {
                    company: "",
                    position: "",
                    description: "",
                    startDate: "",
                    endDate: "",
                  };
                  handleChange("jobExperiences", [
                    ...editableCvData.jobExperiences,
                    newJob,
                  ]);
                }}
              >
                Add Job
              </Button>
            </Box>

            {/* Education */}
            <Box mb={4}>
              <FormLabel>Education</FormLabel>
              {editableCvData.education.map((edu, index) => (
                <Box
                  key={index}
                  mb={3}
                  p={3}
                  border="1px"
                  borderColor="gray.200"
                  borderRadius="8px"
                >
                  <Flex justifyContent="space-between" mb={2}>
                    <Text fontWeight="bold">Education {index + 1}</Text>
                    <Button
                      size="sm"
                      variant="outline"
                      colorScheme="blue"
                      onClick={() => {
                        const updatedEdu = [...editableCvData.education];
                        updatedEdu.splice(index, 1);
                        handleChange("education", updatedEdu);
                      }}
                    >
                      Remove
                    </Button>
                  </Flex>
                  <FormControl mb={2}>
                    <FormLabel>School</FormLabel>
                    <Input
                      value={edu.school}
                      onChange={(e) => {
                        const updatedEdu = [...editableCvData.education];
                        updatedEdu[index].school = e.target.value;
                        handleChange("education", updatedEdu);
                      }}
                      placeholder="School"
                    />
                  </FormControl>
                  <FormControl mb={2}>
                    <FormLabel>Degree</FormLabel>
                    <Input
                      value={edu.degree}
                      onChange={(e) => {
                        const updatedEdu = [...editableCvData.education];
                        updatedEdu[index].degree = e.target.value;
                        handleChange("education", updatedEdu);
                      }}
                      placeholder="Degree"
                    />
                  </FormControl>
                  <Flex>
                    <FormControl mr={2}>
                      <FormLabel>Start Date</FormLabel>
                      <Input
                        type="date"
                        value={edu.startDate}
                        onChange={(e) => {
                          const updatedEdu = [...editableCvData.education];
                          updatedEdu[index].startDate = e.target.value;
                          handleChange("education", updatedEdu);
                        }}
                      />
                    </FormControl>
                    <FormControl>
                      <FormLabel>End Date</FormLabel>
                      <Input
                        type="date"
                        value={edu.endDate}
                        onChange={(e) => {
                          const updatedEdu = [...editableCvData.education];
                          updatedEdu[index].endDate = e.target.value;
                          handleChange("education", updatedEdu);
                        }}
                      />
                    </FormControl>
                  </Flex>
                </Box>
              ))}
              <Button
                size="sm"
                background="#016DDA"
                color="white"
                _hover={{ background: "#016DDA" }}
                onClick={() => {
                  const newEdu = {
                    school: "",
                    degree: "",
                    startDate: "",
                    endDate: "",
                  };
                  handleChange("education", [
                    ...editableCvData.education,
                    newEdu,
                  ]);
                }}
              >
                Add Education
              </Button>
            </Box>
          </ModalBody>

          <ModalFooter>
            <Button
              background="#016DDA"
              color="white"
              _hover={{ background: "#016DDA" }}
              mr={3}
              onClick={handleSubmit}
              isLoading={isSaving}
            >
              Save
            </Button>
            <Button variant="outline" colorScheme="blue" onClick={onClose}>
              Cancel
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </Flex>
  );
};

export default CVDashboard;
