import React, { useEffect } from "react";
import {
  Box,
  FormControl,
  FormLabel,
  Input,
  Select,
  Stack,
  HStack,
  Text,
  Flex,
  Button,
} from "@chakra-ui/react";
import { useForm } from "react-hook-form";
import positions from "../../../data/positions";

const JobDetails = ({ handleNext, addSalariesFullTimeAndPosition, userData }) => {
  const {
    register,
    handleSubmit,
    formState: { errors },
    reset,
  } = useForm({
    defaultValues: {
      minMonthlySalary: userData?.minMonthlySalary || "",
      maxMonthlySalary: userData?.maxMonthlySalary || "",
      position: userData?.position || "",
      modality: userData?.fullTime ? "full-time" : "part-time",
      seniority: userData?.seniority || "",
    },
  });

  useEffect(() => {
    reset({
      minMonthlySalary: userData?.minMonthlySalary || "",
      maxMonthlySalary: userData?.maxMonthlySalary || "",
      position: userData?.position || "",
      modality: userData?.fullTime ? "full-time" : "part-time",
      seniority: userData?.seniority || "",
    });
  }, [userData, reset]);

  const onSubmit = async (data) => {
    const { minMonthlySalary, maxMonthlySalary, modality, position, seniority } = data;
    try {
      // Call the addSalariesFullTimeAndPosition function with the form data
      await addSalariesFullTimeAndPosition(
        minMonthlySalary,
        maxMonthlySalary,
        modality === "full-time",
        position,
        seniority
      );
      handleNext(); // Proceed to the next step if the update is successful
    } catch (error) {
      console.error("Error updating salaries and full-time status:", error);
    }
  };

  return (
    <Box p={5}>
      <form onSubmit={handleSubmit(onSubmit)}>
        <Stack spacing={4}>
          <FormControl isRequired>
            <FormLabel>Salary Range (per month in USD)</FormLabel>
            <HStack>
              <Input
                type="number"
                {...register("minMonthlySalary", {
                  required: "Minimum salary is required",
                })}
                borderRadius="8px"
                border="1px solid #E5E6E6"
                background="white"
                h={12}
              />
              <Text>to</Text>
              <Input
                type="number"
                {...register("maxMonthlySalary", {
                  required: "Maximum salary is required",
                })}
                borderRadius="8px"
                border="1px solid #E5E6E6"
                background="white"
                h={12}
              />
            </HStack>
            {errors.minMonthlySalary && (
              <Text color="red">{errors.minMonthlySalary.message}</Text>
            )}
            {errors.maxMonthlySalary && (
              <Text color="red">{errors.maxMonthlySalary.message}</Text>
            )}
          </FormControl>
          <FormControl isRequired>
            <FormLabel>Position</FormLabel>
            <Select
              {...register("position", { required: "Position is required" })}
              borderRadius="8px"
              border="1px solid #E5E6E6"
              background="white"
              h={12}
            >
              <option value="">Select a position</option>
              {positions.map((position, index) => (
                <option key={index} value={position}>
                  {position}
                </option>
              ))}
            </Select>
            {errors.position && (
              <Text color="red">{errors.position.message}</Text>
            )}
          </FormControl>
          <FormControl isRequired>
            <FormLabel>Work Arrangement</FormLabel>
            <Select
              {...register("modality", { required: "Modality is required" })}
              borderRadius="8px"
              border="1px solid #E5E6E6"
              background="white"
              h={12}
            >
              <option value="">Select a modality</option>
              <option value="part-time">Part-Time</option>
              <option value="full-time">Full-Time</option>
            </Select>
            {errors.modality && (
              <Text color="red">{errors.modality.message}</Text>
            )}
          </FormControl>
          <FormControl isRequired>
            <FormLabel>Seniority Level</FormLabel>
            <Select
              {...register("seniority", { required: "Seniority is required" })}
              borderRadius="8px"
              border="1px solid #E5E6E6"
              background="white"
              h={12}
            >
              <option value="">Select a seniority</option>
              <option value="Junior">Junior</option>
              <option value="MidLevel">MidLevel</option>
              <option value="Senior">Senior</option>
            </Select>
            {errors.seniority && (
              <Text color="red">{errors.seniority.message}</Text>
            )}
          </FormControl>
        </Stack>
        <Flex justifyContent="flex-end" mt={4} px={4}>
          <Button
            sx={{
              borderRadius: "8px",
              background: "#016DDA",
              color: "white",
              textAlign: "center",
              fontFamily: "Segoe UI",
              fontSize: "14px",
              fontWeight: 600,
              _hover: { background: "#004AA4" },
            }}
            onClick={handleSubmit(onSubmit)}
          >
            Next
          </Button>
        </Flex>
      </form>
    </Box>
  );
};

export default JobDetails;