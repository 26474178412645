import React, { useContext, useEffect, useState } from "react";
import {
  Box,
  Flex,
  Image,
  Heading,
  Stack,
  FormControl,
  Input,
  HStack,
  Button,
  Menu,
  MenuButton,
  MenuList,
  MenuItem,
  Text,
} from "@chakra-ui/react";
import { ChevronDownIcon } from "@chakra-ui/icons";
import ReactCountryFlag from "react-country-flag";
import { useForm } from "react-hook-form";
import { countriesByName, countriesByCode } from "../../data/countries";
import { AuthContext } from "../../context";
import parsePhoneNumber from "libphonenumber-js";

const PersonalInformation = () => {
  const { userData, updatePersonalInformation } = useContext(AuthContext);
  const [countryCode, setCountryCode] = useState("+1");

  const {
    register,
    handleSubmit,
    watch,
    setValue,
    formState: { errors },
    reset,
  } = useForm({
    defaultValues: {
      firstName: "",
      lastName: "",
      address: "",
      zipCode: "",
      country: "United States",
      code: "1",
      phoneNumber: "",
      birthDate: "",
      linkedInUrl: "",
    },
  });

  const country = watch("country");
  const code = watch("code");

  // Helper function to get country name from code
  const getCountryName = (code) => {
    const country = countriesByCode.find(
      (country) => country.code === code.toUpperCase()
    );
    return country ? country.name : "United States"; // Default to 'United States' if not found
  };

  // Update form values when userData changes
  useEffect(() => {
    if (userData) {
      const phoneNumber = parsePhoneNumber(userData.phoneNumber || "", "US");
      if (phoneNumber) {
        setCountryCode(phoneNumber.countryCallingCode);

        const countryName = getCountryName(userData.country || "US");

        reset({
          firstName: userData.firstName || "",
          lastName: userData.lastName || "",
          address: userData.address || "",
          zipCode: userData.zipCode || "",
          country: countryName || "US",
          code: phoneNumber.countryCallingCode,
          phoneNumber: phoneNumber.nationalNumber || "",
          birthDate: userData.birthDate || "",
          linkedInUrl: userData.linkedInUrl || "",
        });
      } else {
        const countryName = getCountryName(userData.country || "US");

        reset({
          firstName: userData.firstName || "",
          lastName: userData.lastName || "",
          address: userData.address || "",
          zipCode: userData.zipCode || "",
          country: countryName || "US",
          code: "1",
          phoneNumber: userData.phoneNumber || "",
          birthDate: userData.birthDate || "",
          linkedInUrl: userData.linkedInUrl || "",
        });
      }
    }
  }, [userData, reset]);

  // Synchronize country selection with country code
  useEffect(() => {
    const selectedCountry = countriesByName.find((c) => c.name === country);
    if (selectedCountry) {
      setCountryCode(selectedCountry.dial_code);
      setValue("code", selectedCountry.dial_code.replace("+", ""));
    }
  }, [country, setValue]);

  const onSubmit = async (data) => {
    const fullPhoneNumber = `+${data.code}${data.phoneNumber}`;
    try {
      const codeForCompare = "+" + data.code;

      const fullDataOfCountry = countriesByCode.find(
        (currentCountry) => currentCountry.name === data.country
      );
      // This is because USA and Canada have the same dial_code
      if (
        fullDataOfCountry.name === "United States" ||
        fullDataOfCountry.name === "Canada"
      ) {
        if (codeForCompare !== "+1") {
          return notify(
            "error",
            "Your phone number must me from your country of residency"
          );
        }
      } else {
        if (fullDataOfCountry.dial_code !== codeForCompare) {
          return notify(
            "error",
            "Your phone number must me from your country of residency"
          );
        }
      }

      const selectedCountry = countriesByName.find(
        (c) => c.name === data.country
      );
      let codeOfCountry = selectedCountry?.code;

      await updatePersonalInformation(
        data.address,
        codeOfCountry,
        data.zipCode,
        fullPhoneNumber,
        data.linkedInUrl
      );
    } catch (error) {
      // Handle error
    }
  };

  return (
    <Box width="100%" bg="white" borderRadius="12px">
      <Box p={5}>
        <Heading fontSize={24} mb={4} color="#49424D">
          Personal Information
        </Heading>
        <form onSubmit={handleSubmit(onSubmit)}>
          <Stack spacing={4}>
            {/* First and Last Name */}
            <HStack spacing={4}>
              <FormControl isRequired>
                <Input
                  placeholder="First Name"
                  {...register("firstName")}
                  value={userData?.firstName || ""}
                  isReadOnly
                  borderRadius="8px"
                  border="1px solid #E5E6E6"
                  background="white"
                  h={12}
                />
              </FormControl>

              <FormControl isRequired>
                <Input
                  placeholder="Last Name"
                  {...register("lastName")}
                  value={userData?.lastName || ""}
                  isReadOnly
                  borderRadius="8px"
                  border="1px solid #E5E6E6"
                  background="white"
                  h={12}
                />
              </FormControl>
            </HStack>

            {/* Address */}
            <FormControl isRequired>
              <Input
                placeholder="Address"
                {...register("address", { required: "Address is required" })}
                borderRadius="8px"
                border="1px solid #E5E6E6"
                background="white"
                h={12}
              />
              {errors.address && (
                <Text color="red">{errors.address.message}</Text>
              )}
            </FormControl>

            {/* Zip Code and Country */}
            <HStack spacing={4}>
              <FormControl isRequired>
                <Input
                  placeholder="Zip Code"
                  {...register("zipCode", { required: "Zip code is required" })}
                  borderRadius="8px"
                  border="1px solid #E5E6E6"
                  background="white"
                  h={12}
                />
                {errors.zipCode && (
                  <Text color="red">{errors.zipCode.message}</Text>
                )}
              </FormControl>

              <FormControl isRequired>
                <Menu>
                  <MenuButton
                    as={Button}
                    borderRadius="8px"
                    border="1px solid #E5E6E6"
                    background="white"
                    h={12}
                    pr={16}
                    width="100%"
                    position="relative"
                    _hover={{
                      background: "white",
                      border: "1px solid #E5E6E6",
                    }}
                    _active={{
                      background: "white",
                      border: "1px solid #E5E6E6",
                    }}
                  >
                    <Box display="flex" alignItems="center">
                      <Flex direction="row" fontWeight={400}>
                        <ReactCountryFlag
                          countryCode={
                            countriesByName.find((c) => c.name === country)
                              ?.code || "US"
                          }
                          svg
                          style={{
                            width: "1.5em",
                            height: "1.5em",
                            marginRight: "8px",
                          }}
                        />
                        {country}
                      </Flex>
                    </Box>
                    <ChevronDownIcon
                      position="absolute"
                      right="12px"
                      top="50%"
                      transform="translateY(-50%)"
                    />
                  </MenuButton>
                  <MenuList maxH="200px" overflowY="auto">
                    {countriesByName.map((currentCountry) => (
                      <MenuItem
                        key={currentCountry.name}
                        display="flex"
                        alignItems="center"
                        onClick={() => setValue("country", currentCountry.name)}
                      >
                        <ReactCountryFlag
                          countryCode={currentCountry.code}
                          svg
                          style={{
                            width: "1.5em",
                            height: "1.5em",
                            marginRight: "8px",
                          }}
                        />
                        <Text fontWeight={400}>{currentCountry.name}</Text>
                      </MenuItem>
                    ))}
                  </MenuList>
                </Menu>
              </FormControl>
            </HStack>

            {/* Phone Number */}
            <FormControl isRequired>
              <Flex direction="row" gap={2} width="100%">
                <Menu>
                  <MenuButton
                    as={Button}
                    border="1px solid #E5E6E6"
                    background="white"
                    h={12}
                    width="30%"
                    textAlign="left"
                    position="relative"
                    pr={16}
                    _hover={{
                      background: "white",
                      border: "1px solid #E5E6E6",
                    }}
                    _active={{
                      background: "white",
                      border: "1px solid #E5E6E6",
                    }}
                  >
                    <Box display="flex" alignItems="center">
                      <Flex direction="row" fontWeight={400}>
                        <ReactCountryFlag
                          countryCode={
                            countriesByCode.find(
                              (c) => c.dial_code === `+${code}`
                            )?.code || "US"
                          }
                          svg
                          style={{
                            width: "1.5em",
                            height: "1.5em",
                            marginRight: "8px",
                          }}
                        />
                        {`+${code}`}
                      </Flex>
                    </Box>
                    <ChevronDownIcon
                      position="absolute"
                      right="12px"
                      top="50%"
                      transform="translateY(-50%)"
                    />
                  </MenuButton>

                  <MenuList maxH="200px" overflowY="auto">
                    {countriesByCode.map((countryItem) => (
                      <MenuItem
                        key={countryItem.code}
                        display="flex"
                        alignItems="center"
                        onClick={() => {
                          setValue(
                            "code",
                            countryItem.dial_code.replace("+", "")
                          );
                          setCountryCode(countryItem.dial_code);
                          setValue("country", countryItem.name);
                        }}
                      >
                        <Box marginRight="10px">
                          <Image
                            src={`/images/flags/${countryItem.code}.svg`}
                            alt={`${countryItem.name} flag`}
                            style={{ width: "20px", height: "auto" }}
                          />
                        </Box>
                        {countryItem.dial_code}
                      </MenuItem>
                    ))}
                  </MenuList>
                </Menu>

                <Input
                  name="phoneNumber"
                  type="tel"
                  placeholder="Phone number"
                  {...register("phoneNumber", {
                    required: "Phone number is required",
                    pattern: {
                      value: /^\d{7,15}$/,
                      message: "Enter a valid phone number",
                    },
                  })}
                  borderRadius="8px"
                  border="1px solid #E5E6E6"
                  background="white"
                  h={12}
                  width="70%"
                />
              </Flex>
              {errors.phoneNumber && (
                <Text color="red">{errors.phoneNumber.message}</Text>
              )}
            </FormControl>

            {/* Birth Date */}
            <FormControl isRequired>
              <Input
                type="date"
                {...register("birthDate")}
                value={userData?.birthDate || ""}
                isReadOnly
                borderRadius="8px"
                border="1px solid #E5E6E6"
                background="white"
                h={12}
              />
            </FormControl>

            {/* LinkedIn URL */}
            <FormControl isRequired>
              <Input
                placeholder="LinkedIn URL"
                {...register("linkedInUrl", {
                  required: "LinkedIn URL is required",
                })}
                borderRadius="8px"
                border="1px solid #E5E6E6"
                background="white"
                h={12}
              />
              {errors.linkedInUrl && (
                <Text color="red">{errors.linkedInUrl.message}</Text>
              )}
            </FormControl>

            {/* Submit Button */}
            <Flex justifyContent="flex-end" mt={4} px={4}>
              <Button
                type="submit"
                px={6}
                sx={{
                  borderRadius: "8px",
                  background: "#016DDA",
                  color: "white",
                  textAlign: "center",
                  fontFamily: "Segoe UI",
                  fontSize: "14px",
                  fontWeight: 600,
                  _hover: { background: "#004AA4" },
                }}
              >
                Save
              </Button>
            </Flex>
          </Stack>
        </form>
      </Box>
    </Box>
  );
};

export default PersonalInformation;
