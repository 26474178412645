// components/LoginProcess/SkillsModal.jsx

import React, { useEffect } from "react";
import {
  Button,
  FormControl,
  Select,
  Stack,
  HStack,
  Text,
  Flex,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  useDisclosure,
} from "@chakra-ui/react";
import { AddIcon } from "@chakra-ui/icons";
import { useForm } from "react-hook-form";
import notify from "../../utils/notify";

const SkillsModal = ({ skillsDisplayed, addAssessments }) => {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const { register, handleSubmit, reset } = useForm({
    defaultValues: {
      selectedSkill: skillsDisplayed.length > 0 ? skillsDisplayed[0].id : "",
    },
  });

  useEffect(() => {
    reset({
      selectedSkill: skillsDisplayed.length > 0 ? skillsDisplayed[0].id : "",
    });
  }, [skillsDisplayed, reset]);

  const onSubmit = async (data) => {
    try {
      const selectedSkill = skillsDisplayed.find(
        (skill) => skill.id === data.selectedSkill
      );

      if (!selectedSkill) {
        notify("error", "No skill selected.");
        return;
      }

      await addAssessments(selectedSkill.id);
      notify("success", `Skill Added: ${selectedSkill.name} ${selectedSkill.seniority}`);
      onClose();
    } catch (error) {
    }
  };

  return (
    <>
      <Button
        rightIcon={<AddIcon />}
        px={8}
        sx={{
          borderRadius: "8px",
          background: "#016DDA",
          color: "white",
          textAlign: "center",
          fontFamily: "Segoe UI",
          fontSize: "14px",
          fontWeight: 600,
          _hover: { background: "#004AA4" },
        }}
        onClick={onOpen}
        py={5}
      >
        Add New Skill
      </Button>

      <Modal isOpen={isOpen} onClose={onClose} size="md">
        <ModalOverlay />
        <ModalContent maxW="40%">
          <ModalHeader>Add New Skill</ModalHeader>
          <ModalBody>
            <Text color="#707887" mb={4}>
              Please select a skill to take the test.
            </Text>

            <form id="skills-form" onSubmit={handleSubmit(onSubmit)}>
              <Stack spacing={4}>
                <FormControl isRequired>
                  <Select
                    {...register("selectedSkill")}
                    placeholder="Select a skill"
                    borderRadius="8px"
                    border="1px solid #E5E6E6"
                    background="white"
                    h={12}
                    color="#000F2B"
                    opacity={0.65}
                  >
                    {skillsDisplayed.map((skill) => (
                      <option key={skill.id} value={skill.id}>
                        {`${skill.name} - ${skill.seniority}`}
                      </option>
                    ))}
                  </Select>
                </FormControl>
              </Stack>
            </form>
          </ModalBody>
          <ModalFooter>
            <Flex justifyContent="space-between" width="100%">
              <Button
                px={7}
                sx={{
                  borderRadius: "8px",
                  background: "#DCEBFA",
                  color: "#016DDA",
                  _hover: { background: "#B9DBF5", color: "#005BB5" },
                }}
                onClick={onClose}
              >
                Close
              </Button>
              <Button
                type="submit"
                form="skills-form"
                px={7}
                sx={{
                  borderRadius: "8px",
                  background: "#016DDA",
                  color: "white",
                  _hover: { background: "#004AA4" },
                }}
              >
                Take Test
              </Button>
            </Flex>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </>
  );
};

export default SkillsModal;